import { FunctionComponent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks/store";
import { weweContextActions } from "../../../../app/slice/weweSlice";
import { _portfolioDetailPageBlock, data_portfolio, imageType } from "..";
import { RootState } from "../../../../app/store/store";
import { IMG_TYPE } from "../../../enums/imgType";

const FullscreenComponent: FunctionComponent = () => {

    let scale = useAppSelector((getState: RootState) => getState.wewe.scale);
    let fontSizeScaled = (1.56 * scale).toFixed(3);
    const dispatch = useAppDispatch();
    const path = useAppSelector((getState: RootState) => getState.wewe.fullscreenPage);
    let data: _portfolioDetailPageBlock | null = data_portfolio.filter(d => d.path == path)[0];

    return (
        <div id="desktop-fullscreen">
            <div className="desktop-portfolio-detail-1-zoo">
                <div className="frame-3129-3">
                    <div className="gif-punctuation-1-parent">
                        {data.images.map((image, idx) =>  renderImage(idx, image))}
                    </div>
                </div>
                <div className="fullscreen" onClick={() => dispatch(weweContextActions._showPopupFullScreen(false, null))}>
                    <span style={{ fontSize: `${fontSizeScaled}rem` }}>go back</span>
                </div>
            </div>
        </div>
    );
};

function renderImage(idx: number, input: imageType) {
    switch (input.type) {
        case IMG_TYPE.VIDEO:
            return <video className="fill-width" autoPlay muted loop>
                        <source src={input.img}  />
                    </video>
        default:
            return <img className="gif-punctuation-1-icon"
                        alt=""
                        key={idx}
                        src={input.img} />
    }
}

const Fullscreen = FullscreenComponent;
export default Fullscreen;