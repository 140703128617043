import React, { ReactElement, useContext } from "react";
import LayoutContext from "../../layout/layoutContext";

interface BackgroundI {
  onClick: Function;
  children: any;
  isDesktop: boolean;
}

interface IFunction {
    onClick: Function;
}

const Background = ({children, onClick, isDesktop}: BackgroundI): ReactElement<BackgroundI, "modal-background"> => (
  <div className="background" style={{animation: `${isDesktop ? 'fromRight' : 'fromBottom'} .4s ease-out`}} onClick={() => onClick()}>
    {children}
  </div>
);

const ClickCapturer = ({ onClick, children, isDesktop }: BackgroundI) => (
  <div className="click-capturer" onClick={() => onClick()}>
    {children}
  </div>
);


interface ModalI {
  children: any;
  close: Function;
  isDesktop: boolean;
}

const Modal = ({ children, close, isDesktop}: ModalI) => {
  return (
    <Background onClick={() => close()} isDesktop={isDesktop}>
      <ClickCapturer onClick={() => close()} isDesktop={isDesktop}>
        <div onClick={e => e.stopPropagation()}>{children}</div>
      </ClickCapturer>
    </Background>
  );
};

export default Modal;
