import { useState, useEffect } from 'react'
import useDebounce from '../others/useDebounce'
import { useAppDispatch } from './store'
import { weweContextActions } from '../slice/weweSlice'
import { isDesktop, isMobile, isTablet } from 'react-device-detect'

const useScale = () => {

    const dispatch = useAppDispatch();
    const [scale, setScale] = useState((window.innerWidth ) / (((isDesktop || isTablet) && window.innerWidth > 750) ? 1280 : 360))

    // SCALE
    const newScale = useDebounce(() => {

        let newScale = (window.innerWidth ) / (((isDesktop || isTablet) && window.innerWidth > 750) ? 1280 : 360); 

        setScale(newScale);
        dispatch(weweContextActions._setScale(newScale));
    })

    useEffect(() => {
        window.addEventListener('resize', newScale)

        return () => {
            window.removeEventListener('resize', newScale)
        }
    }) 

    return scale
}

export default useScale