import { useAppDispatch, useAppSelector } from "../../../app/hooks/store";
import { RootState } from "../../../app/store/store";
import Modal from "./modal";
import { weweContextActions } from "../../../app/slice/weweSlice";
import SayHi from "../../pages-components/contact/sayhi";
import { useContext } from "react";
import LayoutContext from "../../layout/layoutContext";

function PopupSayHiComponent() {

    const isDesktop = useContext(LayoutContext);
    const isShowPopup = useAppSelector((getState:RootState) => getState.wewe.showPopupSayHi);
    const dispatch = useAppDispatch();
    
    return (
        isShowPopup && <div id={`${isDesktop ? "desktop" : "mobile"}-popup-container-sayhi`}>
                            <Modal close={() => dispatch(weweContextActions._showPopupSayHi(false))} isDesktop={isDesktop}>
                                <SayHi />
                            </Modal>
                        </div>  
    )
}

const PopupContainerSayHi = PopupSayHiComponent;
export default PopupContainerSayHi;