import { FunctionComponent, useContext } from "react";

import icon_1 from '../../../bordel/group-1004-2.svg';
import icon_2 from '../../../bordel/group-4.svg';
import icon_3 from '../../../bordel/group-1003-2.svg';

import icon_4 from '../../../bordel/group-998.svg';
import icon_5 from '../../../bordel/group-2.svg';
import icon_6 from '../../../bordel/group-997.svg';

import { useAppDispatch, useAppSelector } from "../../../app/hooks/store";
import { weweContextActions } from "../../../app/slice/weweSlice";
import LayoutContext from "../../layout/layoutContext";
import { RootState } from "../../../app/store/store";

const SayHiComponent: FunctionComponent = () => {

    const dispatch = useAppDispatch();
    const isDesktop = useContext(LayoutContext);
    const mailAddress = useAppSelector((getState: RootState) => getState.wewe.mailAddress);
    const mailHref = useAppSelector((getState: RootState) => getState.wewe.mailHref);

    const social_ig = useAppSelector((getState: RootState) => getState.wewe.social_ig);
	const social_be = useAppSelector((getState: RootState) => getState.wewe.social_be);
	const social_in = useAppSelector((getState: RootState) => getState.wewe.social_in);

    return (
        isDesktop ? getDesktopLayout() : getMobileLayout()
    );

    function getMobileLayout() {
        return <div id="mobile-sayhi">
            <div className="mobil-formulr">
                <div className="frame-parent">
                    <div className="wewere-all-ears-parent">
                        <b className="wewere-all-ears-container">
                            <p className="wewere">WEWE’RE,</p>
                            <p className="wewere">ALL EARS”</p>
                        </b>
                        <div className="designwewestudiosk-3">
                            <a className="bewewewewestudiosk-4" href={mailHref}>
                                <b className="bewewewewestudiosk-4">{mailAddress}</b>
                            </a>
                        </div>
                    </div>
                    <b className="take-a-look-2">Take a look!</b>
                    <div className="frame-group">
                        <div className="frame-container">
                            <a href={social_ig} target="_blank" rel="noopener noreferrer">
							    <img className="frame-child" alt="" src={icon_4} />
                            </a>
                            <a href={social_be} target="_blank" rel="noopener noreferrer">
                                <img className="frame-child" alt="" src={icon_5} />
                            </a>
                            <a href={social_in} target="_blank" rel="noopener noreferrer">
                                <img className="frame-child" alt="" src={icon_6} />
                            </a>
                        </div>
                        <div className="frame-child">
                            <div className="rectangle-parent" onClick={() => dispatch(weweContextActions._showPopupSayHi(false))}>
                                <div className="group-child" />
                                <div className="group-item" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    function getDesktopLayout() {
        return <div id="desktop-sayhi">
            <div className="formular-desktop">
                <div className="formular-desktop-child" />
                <div className="frame-parent">
                    <div className="frame-group">
                        <div className="frame-wrapper">
                            <div className="frame-container">
                                <div className="wewere-all-ears-wrapper">
                                    <b className="wewere-all-ears">
                                        <p className="wewere">WeWE’re</p>
                                        <p className="wewere">All Ears”</p>
                                    </b>
                                </div>
                            </div>
                        </div>
                        <div className="designwewestudiosk-2-2">
                            <a className="bewewewewestudiosk-2-2" href={mailHref}>
                                <b className="bewewewewestudiosk-2-2">{mailAddress}</b>
                            </a>
                        </div>
                    </div>
                    <div className="rectangle-parent" onClick={() => dispatch(weweContextActions._showPopupSayHi(false))}>
                        <div className="group-child" />
                        <div className="rectangle-1160-6" />
                    </div>
                </div>
                <div className="group-995-2-2-parent">
                    <a href={social_ig} target="_blank" rel="noopener noreferrer">
                        <img className="group-995-2-2-icon" alt="" src={icon_1} />
                    </a>
                    <a href={social_be} target="_blank" rel="noopener noreferrer">
                        <img className="group-995-2-2-icon" alt="" src={icon_2} />
                    </a>
                    <a href={social_in} target="_blank" rel="noopener noreferrer">
                        <img className="group-995-2-2-icon" alt="" src={icon_3} />
                    </a>
                </div>
                <span className="take-a-look">Take a look!</span>
            </div>
        </div>;
    }
};

const SayHi = SayHiComponent;
export default SayHi;