import { useLocation, useNavigate } from 'react-router';
import { weweContextActions } from '../slice/weweSlice';
import { useAppDispatch } from '../hooks/store';
import { useContext } from 'react';
import LayoutContext from '../../components/layout/layoutContext';

export const useWeweNavigate = () => {
  const navigate = useNavigate();

  const handleClick = (event: any) => {
      event.preventDefault();
      const href = event.currentTarget.getAttribute('href');
      const url = window.location.origin + href;

      if ((event.ctrlKey || event.metaKey) && event.button === 0) {
          window.open(url, '_blank');
      }
      else if (event.shiftKey && event.button === 0) {
          window.open(url, 'newWindow', "");
      }
      else {
          navigate(href);
      }
  };

  return handleClick;
};

export const useWeweNavigateLong = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isDesktop = useContext(LayoutContext);
  const location = useLocation();

  const handleClick = (event: any) => {
    event.preventDefault();
    const href = event.currentTarget.getAttribute('href');  // Get the href attribute of the clicked element
    const url = window.location.origin + href;

    if ((event.ctrlKey || event.metaKey) && event.button === 0) {
        window.open(url, '_blank');
    }
    else if (event.shiftKey && event.button === 0) {
        window.open(url, 'newWindow', "");
      }
    else {
        if(location !== href) {
            navigate(href);                                     // Use navigate to change the route
        }

        if (href === '/') {
            dispatch(weweContextActions._switchStyle())
            
            if(!isDesktop)
                window.scrollTo(0, 0);
            else 
                document.getElementById('scrolling-content')?.scrollTo(0, 0);
        }
    }
  };

  return handleClick;
};