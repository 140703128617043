import { useEffect } from 'react'
import { useAppDispatch } from './store'
import { weweContextActions } from '../slice/weweSlice'

const UseWewe = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        const counterInterval = setInterval(function () {
            dispatch(weweContextActions._incrementWewe());
        }, 1000);

        return () => clearInterval(counterInterval);
    }, [])

    return null;
}

export default UseWewe
