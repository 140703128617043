import { useAppDispatch, useAppSelector } from "../../../app/hooks/store";
import { RootState } from "../../../app/store/store";
import Modal from "./modal";
import { weweContextActions } from "../../../app/slice/weweSlice";
import Fullscreen from "../../pages-components/portfolio/desktop/fullscreen";
import useScale from "../../../app/hooks/useScale";

function PopupFullscreenComponent() {

    const dispatch = useAppDispatch();
    const isShowPopup = useAppSelector((getState: RootState) => getState.wewe.showPopupFullscreen);

    return (
        isShowPopup && <div id="desktop-popup-container-fullscreen"
            >
            <Modal close={() => dispatch(weweContextActions._showPopupFullScreen(false, null))}>
                <Fullscreen />
            </Modal>
        </div>
    )
}

const PopupContainerFullscreen = PopupFullscreenComponent;
export default PopupContainerFullscreen;