import React, { } from 'react';
import App from './App';
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store/store';
import './main.css'
import PopupContainerFullscreen from './components/popup/fullscreen/popupContainerFullscreen';

// SSR
// ReactDOM.hydrateRoot(
//   document.getElementById("root") as HTMLElement,
//   <React.StrictMode>
//         <Provider store={store}>
//           <BrowserRouter>
//             <App />
//             <PopupContainerFullscreen />
//           </BrowserRouter>
//         </Provider>
//     </React.StrictMode>
// );

// CSR

ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
  .render(
    <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App />
            <PopupContainerFullscreen />
          </BrowserRouter>
        </Provider>
    </React.StrictMode>
  );

