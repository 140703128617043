import { useAppDispatch, useAppSelector } from "../../../app/hooks/store";
import { RootState } from "../../../app/store/store";
import Modal from "./modal";
import { weweContextActions } from "../../../app/slice/weweSlice";
import Menu from "../../pages-components/navbar/menu";

function PopupComponent() {

    const dispatch = useAppDispatch();
    const isShowMenu = useAppSelector((getState:RootState) => getState.wewe.showPopupMenu);
    
    return (
        isShowMenu && <div id="mobile-popup-container-menu">
                            <Modal close={() => dispatch(weweContextActions._showPopupMenu(false))}>
                                <Menu />
                            </Modal>
                        </div>  
    )
}

const PopupContainerMenu = PopupComponent;
export default PopupContainerMenu;