import React, { ReactElement } from "react";

interface BackgroundI {
    onClick: Function;
    children: any;
}

interface IFunction {
    onClick: Function;
}

const Background = ({ children, onClick }: BackgroundI): ReactElement<BackgroundI, "modal-background"> => (
    <div className="background" style={{animation: "fromBottom .4s ease-out"}} onClick={() => onClick()}>
        {children}
    </div>
);

const ClickCapturer = ({ onClick, children }: BackgroundI) => (
    <div className="click-capturer" onClick={() => onClick()}>
        {children}
    </div>
);


interface ModalI {
    children: any;
    close: Function;
}

const Modal = ({ children, close }: ModalI) => {
    return (
        <Background onClick={() => close()}>
            <ClickCapturer onClick={() => close()}>
                <div onClick={e => e.stopPropagation()}>{children}</div>
            </ClickCapturer>
        </Background>
    );
};

export default Modal;
