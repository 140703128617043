// eslint-disable-next-liney
import { composeWithDevTools } from '@redux-devtools/extension'; 
import { ConfigureStoreOptions, configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { weweSlice } from '../slice/weweSlice';


const createStore = (
  preloadedState?: ConfigureStoreOptions['preloadedState'] | undefined
) =>
  configureStore({
    reducer: {
      [weweSlice.name]: weweSlice.reducer
    },
    middleware: (gDM:any) => gDM({serializableCheck: false}).concat(listenerMiddlewareInstance.middleware),
    devTools: isDevelopment,
    ...preloadedState,
  })

const listenerMiddlewareInstance = createListenerMiddleware({
    onError: () => console.error,
  })

const isDevelopment = (process.env.NODE_ENV === 'development') && 
  (typeof window !== 'undefined') && 
  ((window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) ? true : false);

export const store = createStore();

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>