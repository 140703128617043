import React, { ReactElement } from "react";

interface BackgroundI {
  onClick: Function;
  children: any;
}

const Background = ({children,onClick}: BackgroundI): ReactElement<BackgroundI, "modal-background"> => (
  <div className="background-fullscreen" onClick={() => onClick()}>
    {children}
  </div>
);

const ClickCapturer = ({ onClick, children }: BackgroundI) => (
  <div className="click-capturer-fullscreen" onClick={() => onClick()}>
    {children}
  </div>
);

interface ModalI {
  children: any;
  close: Function;
}

const Modal = ({ children, close }: ModalI) => {
  return (
    <Background onClick={() => close()}>
      <ClickCapturer onClick={() => close()}><div onClick={e => e.stopPropagation()}>{children}</div></ClickCapturer>
    </Background>
  );
};

export default Modal;
