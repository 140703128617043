import { FunctionComponent } from "react";
import { useAppSelector } from "../../../app/hooks/store";
import { RootState } from "../../../app/store/store";

import wewe_b_1 from "../../../img/wewe/black/wewe_b_1.svg";
import wewe_b_2 from "../../../img/wewe/black/wewe_b_2.svg";
import wewe_b_3 from "../../../img/wewe/black/wewe_b_3.svg";
import wewe_b_4 from "../../../img/wewe/black/wewe_b_4.svg";
import wewe_w_1 from "../../../img/wewe/white/wewe_w_1.svg";
import wewe_w_2 from "../../../img/wewe/white/wewe_w_2.svg";
import wewe_w_3 from "../../../img/wewe/white/wewe_w_3.svg";
import wewe_w_4 from "../../../img/wewe/white/wewe_w_4.svg";

interface _props {
    isBlack: boolean;
    classname: string;
}

const WeweLogo: FunctionComponent<_props> = ({isBlack, classname}) => {
    
    const weweNo = useAppSelector((getState: RootState) => getState.wewe.wewe);
    
    const black = [wewe_b_1, wewe_b_2, wewe_b_3, wewe_b_4];
    const white = [wewe_w_1, wewe_w_2, wewe_w_3, wewe_w_4];

    return (
        <img className={classname} alt="" src={isBlack ? black[weweNo] : white[weweNo]} />
    );
};

export default WeweLogo;