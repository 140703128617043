import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "./store";
import { weweContextActions } from "../slice/weweSlice";
import { isDesktop, isTablet } from "react-device-detect";

export default function ScrollToTop() {
  var _isDesktop = ((isDesktop || isTablet) && window.innerWidth > 750);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const element = _isDesktop ? document.getElementById('scrolling-content') : window;

    if(!_isDesktop) {
      dispatch(weweContextActions._showPopupMenu(false));
    }

    if(element !== null) {
      element.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
}