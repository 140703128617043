
import { Outlet } from "react-router-dom";
import Navbar from "../pages-components/navbar/navbar";
import Footer from "../pages-components/footer/footer";
import ScrollToAnchor from "../../app/hooks/scrollToAnchor";
import ScrollToTop from "../../app/hooks/scrollToTop";
import { useContext } from "react";
import LayoutContext from "./layoutContext";
import UseWewe from "../../app/hooks/useWewe";
import AnimateNavbar from "../../app/hooks/useDesktop";

const DefaultLayout = () => {
	const isDesktop = useContext(LayoutContext);

	return (
		<>
			{isDesktop
				? getDesktopLayout()
				: getMobileLayout()
			}
			<ScrollToAnchor />
			<ScrollToTop />
			<UseWewe />
            <AnimateNavbar />
		</>
	);

	function getDesktopLayout() {
		return 	<>
					<Navbar />

					<div id="scrolling-content">
						<Outlet />
						<Footer />
					</div>
				</>
	}

	function getMobileLayout() {
		return 	<>
					<Outlet />
					<Footer />
				</>
	}
};

export default DefaultLayout;