const og_image = 'wewestudio_og-image-1.jpg';

export const metas = [
    {
        key: "/",
        title: "Slovak Visual Design Studio | wewestudio.com",
        description: "Destination for visually-driven design solutions. That's us! Your visionary collaborators. #bewewe",
        thumbnail: og_image
    },
    {
        key: "/story",
        title: "wewe Behind the scenes | wewestudio.com",
        description: "Get to know the team, our values, our passion for creative design and join us on our journey on the conquest of the Internet 😊.",
        thumbnail: og_image
    },
    {
        key: "/contact",
        title: "don't by shy Give us a try | wewestudio.com",
        description: "Get in touch with wewe studio today! Whether you have questions, want to discuss your project, or give us a feedback, our team is here to help.",
        thumbnail: og_image
    },
    {
        key: "/services",
        title: "Design | Brand | Web | Consulting | wewestudio.com",
        description: "An island where creativity meets functionality. Our mission is to create engaging design solutions, unique brand identities and fast bespoke websites. #sayhi",
        thumbnail: og_image
    },
    {
        key: "/visual-design",
        title: "you'll slay with our Visual Design | wewestudio.com",
        description: "Why settle for an ordinary design when you can embrace the extraordinary? Become part of wewe family.",
        thumbnail: og_image
    },
    {
        key: "/brand-identity",
        title: "unique Brand Identity to die for | wewestudio.com",
        description: "Elevate your brand with a unique identity crafted by wewe studio. From idea to engaging storrytelling, we specialize in building captivating brand identities. ",
        thumbnail: og_image
    },
    {
        key: "/web-development",
        title: "Web Development is our problem | wewestudio.com",
        description: "Specializing in custom web design, e-commerce development, and website audit solutions, we craft stunning websites tailored to your businesses.",
        thumbnail: og_image
    },
    {
        key: "/personal-consulting",
        title: "Personal Consulting for your future | wewestudio.com",
        description: "Team wewe studio offers tailored guidance and solutions to help your business achieve your future big goals.",
        thumbnail: og_image
    },

    {
        key: "/portfolio",
        title: "discover the Art of Design with wewe | wewestudio.com",
        description: "Devil is in the details, it says. Explore our portfolio and let us bring your vision to life. ",
        thumbnail: og_image
    },
    {
        key: "/wewe-logo",
        title: "nooks and crannies of the wewe logo | wewestudio.com",
        description: "The logo of wewe studio epitomizes simplicity, guaranteeing a neutral aesthetic that effortlessly blends with diverse projects.",
        thumbnail: og_image
    },
    {
        key: "/wewe-animation",
        title: "wewe animation and motion design | wewestudio.com",
        description: "At wewe studio, we leverage animation and motion design as a powerful secondary element to enhance engagement and captivate viewers with our content.",
        thumbnail: og_image
    },
    {
        key: "/wewe-website",
        title: "creation of a unique wewe website  | wewestudio.com",
        description: "The wewe website is meticulously crafted to seamlessly complement our brand identity of emphasizing simplicity and minimalism.",
        thumbnail: og_image
    },
    {
        key: "/huraaa-logo",
        title: "say loudly \"horray\" with the HURÁÁÁ logo | wewestudio.com",
        description: "HURÁÁÁ embodies a visual identity designed for an event and party company poised to create unforgettable celebrations.",
        thumbnail: og_image
    },
    {
        key: "/giggle-logo",
        title: "playful letters of the giggle logo | wewestudio.com",
        description: "Giggle logo is crafted for a Slovakia-based children's event company with ambitions for international reach.",
        thumbnail: og_image
    }
]

//module.exports.getMetaByPath = (path:string) => metas.filter(f => f.key === path)[0];