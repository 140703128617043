import { ROUTES } from "../../enums/routes";

import huraaa_01 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-1.png';
import huraaa_02 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-2.gif';
import huraaa_03 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-3.png';
import huraaa_04 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-4.png';
import huraaa_05 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-5.png';
import huraaa_06 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-6.png';
import huraaa_07 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-7.png';
import huraaa_08 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-8.png';
import huraaa_09 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-9.png';
import huraaa_10 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-10.png';
import huraaa_11 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-11.png';
import huraaa_12 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-12.svg';
import huraaa_13 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-13.svg';
import huraaa_14 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-14.svg';
import huraaa_15 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-15.png';
import huraaa_16 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/wewestudio_huraaa-logo-16.png';

import giggle_01 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/wewestudio_giggle-logo-1.png';
import giggle_02 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/wewestudio_giggle-logo-2.png';
import giggle_03 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/wewestudio_giggle-logo-3.png';
import giggle_04 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/wewestudio_giggle-logo-4.png';
import giggle_05 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/wewestudio_giggle-logo-5.svg';
import giggle_06 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/wewestudio_giggle-logo-6.png';

import logo_01 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-1.gif';
import logo_02 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-2.webp';
import logo_03 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-3.gif';
import logo_04 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-4.png';
import logo_05 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-5.webp';
import logo_06 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-6.gif';
import logo_07 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-7.webp';
import logo_08 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-8.webp';
import logo_09 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-9.webp';
import logo_10 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/wewestudio_wewe-logo-10.webp';

import animation_01 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-1.svg';
import animation_02 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-2.svg';
import animation_03 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-3.svg';
import animation_04 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-4.svg';
import animation_05 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-5.svg';
import animation_06 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-6.svg';
import animation_07 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-7.svg';
import animation_08 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/wewestudio_wewe-animation-8.svg';

import website_01 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/wewestudio_wewe-website-1.gif';
import website_02 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/wewestudio_wewe-website-2.webm';
import website_03 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/wewestudio_wewe-website-3.gif';
import website_04 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/wewestudio_wewe-website-4.gif';
import website_05 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/wewestudio_wewe-website-5.webp';
import website_06 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/wewestudio_wewe-website-6.gif';

// portfolio main 
import thumb_huraaa_01 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/THUMBNAIL/wewestudio_portfolio_huraaa-logo-thumbnail-1.png';
import thumb_huraaa_02 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/THUMBNAIL/wewestudio_portfolio_huraaa-logo-thumbnail-2.png';
import thumb_huraaa_03 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/THUMBNAIL/wewestudio_portfolio_huraaa-logo-thumbnail-3.png';
import thumb_huraaa_04 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/THUMBNAIL/wewestudio_portfolio_huraaa-logo-thumbnail-4.png';
import thumb_huraaa_05 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/THUMBNAIL/wewestudio_portfolio_huraaa-logo-thumbnail-5.png';
import thumb_huraaa_06 from '../../../img/WEB/PORTFOLIO - HURAAA LOGO/THUMBNAIL/wewestudio_portfolio_huraaa-logo-thumbnail-6.png';

import thumb_giggle_01 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/THUMBNAIL/wewestudio_portfolio_giggle-logo-thumbnail-1.png';
import thumb_giggle_02 from '../../../img/WEB/PORTFOLIO - GIGGLE LOGO/THUMBNAIL/wewestudio_portfolio_giggle-logo-thumbnail-2.svg';

import thumb_logo_01 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/THUMBNAIL/wewestudio_portfolio_wewe-logo-thumbnail-1.jpg';
import thumb_logo_02 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/THUMBNAIL/wewestudio_portfolio_wewe-logo-thumbnail-2.jpg';
import thumb_logo_03 from '../../../img/WEB/PORTFOLIO - WEWE.LOGO/THUMBNAIL/wewestudio_portfolio_wewe-logo-thumbnail-3.jpg';

import thumb_anim_01 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/THUMBNAIL/wewestudio_portfolio_wewe-animation-thumbnail-1.jpg';
import thumb_anim_02 from '../../../img/WEB/PORTFOLIO - WEWE.ANIMATION/THUMBNAIL/wewestudio_portfolio_wewe-animation-thumbnail-2.svg';

import thumb_dsgn_01 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/THUMBNAIL/wewestudio_portfolio_wewe-website-thumbnail-1.png';
import thumb_dsgn_02 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/THUMBNAIL/wewestudio_portfolio_wewe-website-thumbnail-2.png';
import thumb_dsgn_03 from '../../../img/WEB/PORTFOLIO - WEWE.WEBSITE/THUMBNAIL/wewestudio_portfolio_wewe-website-thumbnail-3.png';
import { IMG_TYPE } from "../../enums/imgType";

export interface imageType {
    img: any;
    type: IMG_TYPE;
}
export interface portfolioBlock {
    text: string;
    images: imageType[];
    path: ROUTES;
}

export interface _portfolioDetailPageBlock {
    path: ROUTES;
    images: imageType[];
    topRow: string;
    row01: string;
    row02: string;
    desc01: string;
    desc02: string;
}

export const dataPortfolio = [
    {
        text: '!brand naming!  !visual identity!',
        images: [ 
            { img: thumb_huraaa_01, type: IMG_TYPE.IMG},
            { img: thumb_huraaa_02, type: IMG_TYPE.IMG},
            { img: thumb_huraaa_03, type: IMG_TYPE.IMG},
            { img: thumb_huraaa_04, type: IMG_TYPE.IMG},
            { img: thumb_huraaa_05, type: IMG_TYPE.IMG},
            { img: thumb_huraaa_06, type: IMG_TYPE.IMG}],
        path: ROUTES.PORTFOLIO_HURAAALOGO
    },
    {
        text: '!brand naming!  !logo design!',
        images: [ 
            { img: thumb_giggle_01, type: IMG_TYPE.IMG},
            { img: thumb_giggle_02, type: IMG_TYPE.SVG}],
        path: ROUTES.PORTFOLIO_GIGGLELOGO
    },
    {
        text: '!brand naming!  !visual identity!',
        images: [ 
            { img: thumb_logo_01, type: IMG_TYPE.IMG},
            { img: thumb_logo_02, type: IMG_TYPE.IMG},
            { img: thumb_logo_03, type: IMG_TYPE.IMG}],
        path: ROUTES.PORTFOLIO_WEWELOGO
    },
    {
        text: '!motion! !animation!',
        images: [
            { img: thumb_anim_01, type: IMG_TYPE.IMG}, 
            { img: thumb_anim_02, type: IMG_TYPE.SVG}],
        path: ROUTES.PORTFOLIO_WEWEANIMATION
    },
    {
        text: '!UI/UX design! !web development!',
        images: [
            { img: thumb_dsgn_01, type: IMG_TYPE.IMG}, 
            { img: thumb_dsgn_02, type: IMG_TYPE.IMG}, 
            { img: thumb_dsgn_03, type: IMG_TYPE.IMG}],
        path: ROUTES.PORTFOLIO_WEWEWEBSITE
    }
] as portfolioBlock[];

export const data_portfolio: _portfolioDetailPageBlock[] = [
    {
        path: ROUTES.PORTFOLIO_HURAAALOGO,
        images: [
            { img: huraaa_01, type: IMG_TYPE.IMG }, 
            { img: huraaa_02, type: IMG_TYPE.GIF },
            { img: huraaa_03, type: IMG_TYPE.IMG },
            { img: huraaa_04, type: IMG_TYPE.IMG },
            { img: huraaa_05, type: IMG_TYPE.IMG },
            { img: huraaa_06, type: IMG_TYPE.IMG },
            { img: huraaa_07, type: IMG_TYPE.IMG },
            { img: huraaa_08, type: IMG_TYPE.IMG },
            { img: huraaa_09, type: IMG_TYPE.IMG },
            { img: huraaa_10, type: IMG_TYPE.IMG },
            { img: huraaa_11, type: IMG_TYPE.IMG },
            { img: huraaa_12, type: IMG_TYPE.SVG },
            { img: huraaa_13, type: IMG_TYPE.SVG },
            { img: huraaa_14, type: IMG_TYPE.SVG },
            { img: huraaa_15, type: IMG_TYPE.IMG },
            { img: huraaa_16, type: IMG_TYPE.IMG }
        ],
        topRow: '!brand naming!  !visual identity!',
        row01: 'HURÁÁÁ',
        row02: 'logo',
        desc01: `HURÁÁÁ embodies a visual identity that \nresonates with the heartwarming cheer \nof "hooray!" in Slovak, designed for an event \nand party company poised to create \nunforgettable celebrations. This branding \ninitiative draws heavily on nostalgia, with \na color scheme and design elements that \nharken back to the carefree and joyful days \nof childhood. `,
        desc02: `The logo, versatile in its adaptability, features \na dynamic diagonal composition as it’s \ncenterpiece, capturing the essence \nof movement and spontaneity that defines \nchildren's play. The essence of HURÁÁÁ \nis further brought to life through animations \nand real-time physics, mimicking \nthe delightful randomness of toys scattered \nin play.`,
    },
    {
        path: ROUTES.PORTFOLIO_GIGGLELOGO,
        images: [
            { img: giggle_01, type: IMG_TYPE.IMG },
            { img: giggle_02, type: IMG_TYPE.IMG },
            { img: giggle_03, type: IMG_TYPE.IMG },
            { img: giggle_04, type: IMG_TYPE.IMG },
            { img: giggle_05, type: IMG_TYPE.SVG },
            { img: giggle_06, type: IMG_TYPE.IMG }
        ],
        topRow: '!brand naming!  !logo design!',
        row01: 'giggle',
        row02: 'logo',
        desc01: `Giggle, emblematic of a strategic branding \ninitiative, is crafted for a Slovakia-based \nchildren's event company with ambitions \nfor international reach. The brand name, \nGiggle, selected for it's linguistic simplicity \nand cross-cultural applicability, serves as \na core component of the company's identity, \nsignaling its sector focus and future-oriented \nbusiness strategy.`,
        desc02: `The logo employs a typographic solution \ncharacterized by playful, lowercase letters, \nalongside a composition strategy that \nembraces randomness and flexibility, \nmirroring the dynamic nature of children's \nentertainment. This design approach \nis underpinned by a rigorous, analytical \nframework, ensuring clarity, usability, and \nscalability across diverse media platforms, \nwithout reliance on conventional marketing \nrhetoric.`,
    },
    {
        path: ROUTES.PORTFOLIO_WEWELOGO,
        images: [
            { img: logo_01, type: IMG_TYPE.GIF }, 
            { img: logo_02, type: IMG_TYPE.IMG },
            { img: logo_03, type: IMG_TYPE.GIF },
            { img: logo_04, type: IMG_TYPE.IMG },
            { img: logo_05, type: IMG_TYPE.IMG },
            { img: logo_06, type: IMG_TYPE.GIF },
            { img: logo_07, type: IMG_TYPE.IMG },
            { img: logo_08, type: IMG_TYPE.IMG },
            { img: logo_09, type: IMG_TYPE.IMG },
            { img: logo_10, type: IMG_TYPE.IMG }
        ],
        topRow: '!brand naming!  !visual identity!',
        row01: 'wewe.',
        row02: 'logo',
        desc01: `The logo of our design studio epitomizes simplicity, guaranteeing a neutral aesthetic that effortlessly blends with diverse projects. Complemented by punctuations such as ., ", ?, or !, it introduces a dynamic and unpredictable element. These symbols signify the four service categories offered by wewe.`,
        desc02: `Designed for optimal impact, the logo excels on a plain black-on-white background, creating a visually striking contrast. It also adapts seamlessly to primary and secondary color combinations, featuring two complementary hues – purple and yellow.`,
    },
    {
        path: ROUTES.PORTFOLIO_WEWEANIMATION,
        images: [
            { img: animation_01, type: IMG_TYPE.SVG },
            { img: animation_02, type: IMG_TYPE.SVG },
            { img: animation_03, type: IMG_TYPE.SVG },
            { img: animation_04, type: IMG_TYPE.SVG },
            { img: animation_05, type: IMG_TYPE.SVG },
            { img: animation_06, type: IMG_TYPE.SVG },
            { img: animation_07, type: IMG_TYPE.SVG },
            { img: animation_08, type: IMG_TYPE.SVG }
        ],
        topRow: '!motion! !animation!',
        row01: 'wewe.',
        row02: 'animation',
        desc01: `At wewe. design studio, we leverage animations and motion design as a powerful secondary element to enhance engagement and captivate viewers with our content.`,
        desc02: `Our unique approach involves incorporating existing Apple emojis as the primary animation elements. These emojis serve as modular components, disintegrating, destroying, and interacting in various captivating ways.`,
    },
    {
        path: ROUTES.PORTFOLIO_WEWEWEBSITE,
        images: [
           { img: website_01, type: IMG_TYPE.GIF },
           { img: website_02, type: IMG_TYPE.VIDEO }, 
           { img: website_03, type: IMG_TYPE.GIF },
           { img: website_04, type: IMG_TYPE.GIF },
           { img: website_05, type: IMG_TYPE.IMG },
           { img: website_06, type: IMG_TYPE.GIF }
        ],
        topRow: '!UI/UX design! !web development!',
        row01: 'wewe.',
        row02: 'website',
        desc01: `The wewe. website is meticulously crafted to seamlessly complement the brand identity of wewe., emphasizing simplicity and minimalism. It boasts a bold and aggressive use of typography, strategically incorporating pops of colors while predominantly relying on a classic black-and-white palette`,
        desc02: `Following a standard structure, the website features nuanced elements that guide users through a thoughtfully designed user interface (UI) and user experience (UX). The primary button color, a vibrant purple, takes center stage, offering a visually engaging navigation experience. In contrast, the complementary yellow is employed sparingly, strategically placed for maximum impact and emphasis.`,
    }
]