import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from './store';
import { weweContextActions } from '../slice/weweSlice';

function ScrollToAnchor() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const lastHash = useRef('');
  
  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); 
    }

    // SAY HI on LOAD >>  show MODAL
    if(lastHash.current && lastHash.current.toLowerCase() === 'sayhi') {
      dispatch(weweContextActions._showPopupSayHi(true));
      lastHash.current = '';
    }

    // jump to ANCHOR
    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return null;
}

export default ScrollToAnchor;