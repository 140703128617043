// REDUCER vs EXTRA_REDUCER
// https://codesandbox.io/s/github/reduxjs/redux-toolkit/tree/master/examples/query/react/authentication?from-embed=&file=/src/features/auth/authSlice.tsx
// https://codesandbox.io/s/github/reduxjs/redux-toolkit/tree/master/examples/query/react/authentication-with-extrareducers?from-embed=&file=/src/features/auth/authSlice.tsx

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../store/store';
import { ROUTES } from '../../components/enums/routes';

export interface WeweState {
    
    wewe: number;

    scale: number;

    colorNo: number;
    color: string;
    
    showNavbar: boolean;
    animeNavbar: string;
    showPopupSayHi: boolean;
    showPopupMenu: boolean;
    showPopupFullscreen: boolean;
    fullscreenPage: ROUTES | null;

    mailAddress: string;
    mailHref: string;

    social_ig: string;
    social_in: string;
    social_be: string;

}

const colors = ['one', 'two', 'three'];
const firstNo = Math.floor(Math.random() * (300 - 100 + 1)) + 100;

const initialState: WeweState = {
    wewe: (firstNo % 4),
    scale: 1,
    colorNo: firstNo+1,
    color: colors[firstNo % colors.length],
    showNavbar: true,
    animeNavbar: '',
    showPopupSayHi: false,
    showPopupMenu: false,
    showPopupFullscreen: false,
    fullscreenPage: null,

    mailAddress: 'bewewe@wewestudio.com',
    mailHref: 'mailto:bewewe@wewestudio.com?subject=Feedback&body=Hi Wewe,\n',

    social_ig: 'https://www.instagram.com/wewe.studio?igsh=a2RtbHpqcDd3aHA2&utm_source=qr',
    social_in: 'https://www.linkedin.com/company/wewe-studio/',
    social_be: 'https://www.behance.net/wewestudio'
}

export const weweSlice = createSlice({
  name: 'wewe',
  initialState,
  reducers: {
    setScale: (state, action: PayloadAction<{scale: number}>) => {
      return {
        ...state,
        scale: action.payload.scale
      }
    },
    incrementWewe: state => {
      return {
        ...state,
        wewe: ((state.wewe + 1) % 4)
      }
    },
    switchStyle: state => {
      return {
        ...state,
        colorNo: state.colorNo + 1,
        color: colors[state.colorNo % colors.length]
      }
    },
    showNavbar: (state, action: PayloadAction<{show: boolean}>) => {
      return {
        ...state,
        showNavbar: action.payload.show
      }
    },
    animeNavbar: (state, action: PayloadAction<{animation: string}>) => {
      return {
        ...state,
        animeNavbar: action.payload.animation
      }
    },
    showPopupSayHi: (state, action: PayloadAction<{show: boolean}>) => {
      return {
        ...state,
        showPopupSayHi: action.payload.show
      }
    },
    showPopupMenu: (state, action: PayloadAction<{show: boolean}>) => {
      return {
        ...state,
        showPopupMenu: action.payload.show
      }
    },
    showPopupFullscreen: (state, action: PayloadAction<{show: boolean, path:ROUTES | null}>) => {
      return {
        ...state,
        showPopupFullscreen: action.payload.show,
        fullscreenPage: action.payload.path
      }
    }
  },
  extraReducers: (builder) => {
  },
});

const weweActions = weweSlice.actions;

export const selectWewe = (state: RootState) => state.wewe;

export default weweSlice.reducer;

export const weweContextActions = {
  _switchStyle: () => async(dispatch:AppDispatch) => {
    dispatch(weweActions.switchStyle());
  },
  _showNavbar: (show:boolean) => async(dispatch:AppDispatch) => {
    dispatch(weweActions.showNavbar({show} as any));
  },
  _animeNavbar: (animation:string) => async(dispatch:AppDispatch) => {
    dispatch(weweActions.animeNavbar({animation} as any));
  },
  _showPopupSayHi: (show:boolean) => async(dispatch:AppDispatch) => {
    dispatch(weweActions.showPopupSayHi({show} as any));
  },
  _showPopupMenu: (show:boolean) => async(dispatch:AppDispatch) => {
    dispatch(weweActions.showPopupMenu({show} as any));
  },
  _showPopupFullScreen: (show:boolean, path: ROUTES | null) => async(dispatch:AppDispatch) => {
    dispatch(weweActions.showPopupFullscreen({show, path} as any));
  },
  _setScale: (scale:number) => async(dispatch:AppDispatch) => {
    dispatch(weweActions.setScale({scale} as any));
  },
  _incrementWewe: () => async(dispatch:AppDispatch) => {
    dispatch(weweActions.incrementWewe());
  }
};

