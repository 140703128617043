import React, { lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router';
import DefaultLayout from './components/layout/defaultLayout';
import AppLoader from './components/loader/appLoader';
import { useAppSelector } from './app/hooks/store';
import useScale from './app/hooks/useScale';
import { RootState } from './app/store/store';
import { ROUTES } from './components/enums/routes';
import PopupContainerMenu from './components/popup/menu/popupContainerMenu';
import PopupContainerSayHi from './components/popup/sayhi/popupContainerSayHi';
import LayoutContext from './components/layout/layoutContext';
import { isDesktop, isTablet } from 'react-device-detect';
import useDebounce from './app/others/useDebounce';
import { metas } from './metas';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from './components/pages-components/navbar/navbar';

const LazyNotFound = lazy(() => import('./components/pages/page_404'));
const LazyLanding = lazy(() => import('./components/pages/page_landing'));
const LazyContacts = lazy(() => import('./components/pages/page_contact'));
const LazyStory = lazy(() => import('./components/pages/page_story'));
const LazyServices = lazy(() => import('./components/pages/page_services'));
const LazyServicesDetailLeft = lazy(() => import('./components/pages/page_services_detail_left'));
const LazyServicesDetailRight = lazy(() => import('./components/pages/page_services_detail_right'));
const LazyPortfolio = lazy(() => import('./components/pages/page_portfolio'));
const LazyPortfolioDetail = lazy(() => import('./components/pages/page_portfolio_detail'));

function App() {
    const location = useLocation();
    const [meta, setMeta] = useState(metas[0]);

    const isShowNavbar = useAppSelector((getState: RootState) => getState.wewe.showNavbar);
    const animeNavbar = useAppSelector((getState: RootState) => getState.wewe.animeNavbar);

    const isShowMenu = useAppSelector((getState: RootState) => getState.wewe.showPopupMenu);
    const isShowPopup = useAppSelector((getState: RootState) => getState.wewe.showPopupSayHi);

    const [isLayoutDesktop, setLayoutDesktop] = useState((isDesktop || isTablet) && window.innerWidth > 750);
    const findLayout = useDebounce(() => { setLayoutDesktop((isDesktop || isTablet) && window.innerWidth > 750); })

    useEffect(() => {
        window.addEventListener('resize', findLayout)
        window.addEventListener('load', findLayout)

        return () => {
            window.removeEventListener('resize', findLayout)
            window.removeEventListener('load', findLayout)
        }
    }, [isLayoutDesktop])

    useEffect(() => {
        var data = metas.filter(f => f.key === location.pathname)[0];
        if (data)
            setMeta(data);
    }, [location]);

    const scale = useScale();
    const newHeightscale = isLayoutDesktop ? `${(100 / scale).toFixed(3)}vh` : '';
    const newGridNavBar = isLayoutDesktop ? 43 : 60;
    const gridTemplateRows = isShowNavbar ? (isLayoutDesktop ? `${newGridNavBar}px auto` : `auto ${newGridNavBar}px`) : "auto";
    const className = `${isLayoutDesktop ? 'desktop ' : 'mobile '} ${animeNavbar}${isShowNavbar ? '' : 'hiddenNavbar'}`;
    const classNameMobile = `${animeNavbar}${isShowNavbar ? '' : 'hiddenNavbar'}`;
    const transform = `scale(${scale},${scale})`;
    const helmetContext = {};

    const navbarStyle = {
        position: 'fixed', // Fix the navbar to the bottom of the screen
        width: '100%', // Full width
        height: '60px', // 60px tall
        backgroundColor: '#333', // Background color
        color: 'white', // Text color
        display: 'flex', // Use flexbox for alignment
        justifyContent: 'center', // Center items horizontally
        alignItems: 'center' // Center items vertically
    };

    return (
        <HelmetProvider context={helmetContext}>
            <LayoutContext.Provider value={isLayoutDesktop}>

                <div id="layout"
                    className={className}
                    style={{
                        transform: transform,
                        height: newHeightscale,
                        gridTemplateRows: gridTemplateRows
                    }} >

                    {meta !== null &&
                        <Helmet>
                            <title>{meta.title}</title>
                            <meta name="og:title" content={meta.title} />
                            <meta name="og:description" content={meta.description} />
                            <meta name="description" content={meta.description} />
                        </Helmet>
                    }

                    <React.Suspense fallback={<AppLoader />}>
                        <Routes>
                            <Route element={<DefaultLayout />}>

                                <Route index path={ROUTES.LANDING} element={<LazyLanding />} />
                                <Route path={ROUTES.STORY} element={<LazyStory />} />
                                <Route path={ROUTES.CONTACT} element={<LazyContacts />} />

                                <Route path={ROUTES.SERVICES} element={<LazyServices />} />
                                <Route path={ROUTES.SERVICES_VISUALDESIGN} element={<LazyServicesDetailLeft path={ROUTES.SERVICES_VISUALDESIGN} />} />
                                <Route path={ROUTES.SERVICES_BRANDIDENTITY} element={<LazyServicesDetailRight path={ROUTES.SERVICES_BRANDIDENTITY} />} />
                                <Route path={ROUTES.SERVICES_WEBDEVELOPMENT} element={<LazyServicesDetailLeft path={ROUTES.SERVICES_WEBDEVELOPMENT} />} />
                                <Route path={ROUTES.SERVICES_PERSONALCONSULTING} element={<LazyServicesDetailRight path={ROUTES.SERVICES_PERSONALCONSULTING} />} />

                                <Route path={ROUTES.PORTFOLIO} element={<LazyPortfolio />} />
                                
                                <Route path={ROUTES.PORTFOLIO_HURAAALOGO} element={<LazyPortfolioDetail path={ROUTES.PORTFOLIO_HURAAALOGO} />} />
                                <Route path={ROUTES.PORTFOLIO_GIGGLELOGO} element={<LazyPortfolioDetail path={ROUTES.PORTFOLIO_GIGGLELOGO} />} />

                                <Route path={ROUTES.PORTFOLIO_WEWEANIMATION} element={<LazyPortfolioDetail path={ROUTES.PORTFOLIO_WEWEANIMATION} />} />
                                <Route path={ROUTES.PORTFOLIO_WEWELOGO} element={<LazyPortfolioDetail path={ROUTES.PORTFOLIO_WEWELOGO} />} />
                                <Route path={ROUTES.PORTFOLIO_WEWEWEBSITE} element={<LazyPortfolioDetail path={ROUTES.PORTFOLIO_WEWEWEBSITE} />} />

                            </Route>
                            <Route path={ROUTES.ASTERISK} element={<Navigate replace to="/404" />} />
                            <Route path={ROUTES._404} element={<LazyNotFound />} />
                        </Routes>
                    </React.Suspense>


                </div >

                {/* MOBILE - NAVBAR */}
                {(!isLayoutDesktop && (location.pathname !== `/${ROUTES._404}`)) && <div id="fixed-mobil-navbar" className={classNameMobile}>
                    {isShowNavbar && <div id="proportionalnavbar" style={{ transform: transform}}>
                        <Navbar />  
                    </div>
                    }
                </div>
                }

                {/* MOBILE - MENU + SAYHI */}
                {!isLayoutDesktop && (isShowMenu || isShowPopup) && <div id="mobil-yellow" className="m-fixed-yellow">
                    <div className="m-proportionalpopup"
                        style={{ transform: transform }} >
                        <PopupContainerMenu />
                        <PopupContainerSayHi />
                    </div>
                </div>
                }

                {/* DESKTOP - SAYHI */}
                {isLayoutDesktop && isShowPopup && <div id="desktop-yellow" className="d-fixed-yellow">
                    <div className="d-proportionalpopup"
                        style={{ transform: transform, height: newHeightscale }} >
                        <PopupContainerSayHi />
                    </div>
                </div>
                }

            </LayoutContext.Provider>
        </HelmetProvider>
    );

}

export default App;