import { useContext, useEffect, useState } from 'react'
import useDebounce from '../others/useDebounce'
import { useAppDispatch, useAppSelector } from './store'
import { weweContextActions } from '../slice/weweSlice'
import { RootState } from '../store/store'
import LayoutContext from '../../components/layout/layoutContext'

export default function AnimateNavbar() {

    const isDesktop = useContext(LayoutContext);
    const showNavbar = useAppSelector((getState: RootState) => getState.wewe.showNavbar);

    const dispatch = useAppDispatch();
    const [memoryFooterVisibility, setMemoryFooterVisibility] = useState(false);
    const [memoryFooterOverNavbarVisibility, setMemoryFooterOverNavbarVisibility] = useState(false);
    const [memoryHeaderPosition, setMemoryHeaderPosition] = useState(-1);
    
    // NAVBAR MOBIL / DESKTOP
    useEffect(() => {
        const element = isDesktop ? document.getElementById('scrolling-content') : window;
       
        if (element !== null) {
            element.addEventListener('scroll', fncShowHideNavbar)
            element.addEventListener('resize', fncShowHideNavbar)

            return () => {
                element.removeEventListener('scroll', fncShowHideNavbar)
                element.removeEventListener('resize', fncShowHideNavbar)
            }
        }
    })

    function animation(isFooterOverNavbar: boolean) {

        if(isFooterOverNavbar) {
            // over >> anime + hide
            dispatch(weweContextActions._animeNavbar('closing'));
            setTimeout(() => {
                dispatch(weweContextActions._showNavbar(false));
                dispatch(weweContextActions._animeNavbar(''));
            }, 200);
        } else {
            // below >> show + anime
            dispatch(weweContextActions._showNavbar(true));
            dispatch(weweContextActions._animeNavbar('opening'));
            setTimeout(() => {
                dispatch(weweContextActions._animeNavbar(''));
            }, 200);
        }
    }

    const fncShowHideNavbar = useDebounce(() => {
        if (isDesktop) {
            const footer = document.getElementById("desktop-footer");
            let footer_rect = footer?.getBoundingClientRect();
            const navbar = document.getElementById("wewe-navbar");
            let navbar_rect = navbar?.getBoundingClientRect();

            if (footer_rect != null) {

                // ulozim si Bottom pre NAVBAR
                if(navbar_rect != null) {
                    setMemoryHeaderPosition(navbar_rect?.bottom);
                }

                let isFooterOverNavbar = footer_rect.top <= (memoryFooterOverNavbarVisibility ? 0 : memoryHeaderPosition); //(navbar_rect?.bottom ?? 0);

                // porovnaj + animuj
                if ((isFooterOverNavbar !== memoryFooterOverNavbarVisibility) 
                    || (isFooterOverNavbar === showNavbar)) {
                    
                    setMemoryFooterOverNavbarVisibility(isFooterOverNavbar);
                    animation(isFooterOverNavbar);
                }
            }
        } else { // MOBILE

            const footer = document.getElementById("mobile-footer");
            let footer_rect = footer?.getBoundingClientRect();

            if (footer_rect != null) {
                let isVisibleFooter = footer_rect.top < window.innerHeight;
                if ((isVisibleFooter !== memoryFooterVisibility) 
                    || (isVisibleFooter === showNavbar)) {
                    setMemoryFooterVisibility(isVisibleFooter);
                    animation(isVisibleFooter);
                }
            }
        }
    }, 100) 

    return null;
}