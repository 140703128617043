export enum ROUTES {
    ASTERISK = '*',
    _404 = '404',
    SLASH = '/',
    
    LANDING = '',
    STORY = '/story',
    CONTACT = '/contact',
    SERVICES = '/services',
    SERVICES_VISUALDESIGN = '/visual-design',
    SERVICES_BRANDIDENTITY = '/brand-identity',
    SERVICES_WEBDEVELOPMENT = '/web-development',
    SERVICES_PERSONALCONSULTING = '/personal-consulting',
    PORTFOLIO = '/portfolio',
    PORTFOLIO_WEWEANIMATION = '/wewe-animation',
    PORTFOLIO_WEWELOGO = '/wewe-logo',
    PORTFOLIO_WEWEWEBSITE = '/wewe-website',
    PORTFOLIO_GIGGLELOGO = '/giggle-logo',
    PORTFOLIO_HURAAALOGO = '/huraaa-logo',
}